<template>
  <div class="page">
    <div class="pageMain">
      <div class="tableTopBtn">
        <el-button size="mini" type="primary" :loading="submitBtnLoad" :disabled="alertTlt != '编辑调整单'"
          @click="handleSubmit('formData')">保存
        </el-button>
        <el-button size="mini" type="primary" :loading="submitBtnLoad" :disabled="alertTlt != '新增调整单'"
          @click="handleSubmit('formData')">保存并新增
        </el-button>
      </div>
      <el-form :model="formData" :inline="true" :rules="addRules" ref="formData" label-width="100px"
        class="demo-ruleForm text-center">
        <el-form-item label="仓库：" prop="warehouseId">
          <el-select v-model="formData.warehouseId" @change="changeWarehouse($event)" filterable placeholder="请选择仓库" style="width: 150px" :disabled="pageFlag == 3">
            <el-option v-for="(item, index) in warehouseList" :key="index" :label="item.name"
              :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="单据日期：">
          <el-date-picker v-model="formData.orderTime" value-format="yyyy-MM-dd HH:mm:ss" type="date" placeholder="请选择单据日期" :disabled="pageFlag == 3">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="价格(元)：">
          <el-input v-model.number="formData.price" :disabled="true" placeholder="请输入价格" style="width: 150px"></el-input>
        </el-form-item>
        <el-form-item label="备注：">
            <el-input type="textarea" v-model="formData.remark" placeholder="请输入备注信息" :disabled="pageFlag == 3"></el-input>
          </el-form-item>
      </el-form>
      <div class="userTable goodsTable">
        <template>
          <el-table :data="tableData" style="width: 100%" header-row-class-name="t-header" :row-class-name="mainTableRowClassName" @row-click="mainTableRowClick" @cell-mouse-enter="mainTableCellHover" @cell-mouse-leave="mainTableCellLeave" :show-summary="true">
            <el-table-column type="index" width="100" align="center">
              <template slot-scope="scope">
                <p v-if="hoverRow != scope.$index || pageFlag == 3">{{ scope.$index + 1 }}</p>
                <div class="addOrDel" v-else>
                  <i class="el-icon-circle-plus-outline" style="color: #409EFF;" @click.stop="addRowClick"></i>
                  <i class="el-icon-circle-close" style="color: #F56C6C" @click.stop="delRowClick(scope.$index)"></i>
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="code" label="商品编号">
          </el-table-column> -->
            <el-table-column prop="name" label="商品名称">
              <template slot-scope="scope">
                <!-- 自定义商品筛选组件 -->
                <goods-change ref="goodsChange" v-show="mainTableIndex == scope.$index && pageFlag != 3" :mainIndex="scope.$index" :nowRowData="scope.row" :warehouseId="formData.warehouseId" pageType="purchase" @popupClick="getPopupGoods" @goodsSelectEvent="goodsDataEvent"></goods-change>
                <div v-show="mainTableIndex != scope.$index || pageFlag == 3" style="padding: 0 10px;">{{ scope.row.goodsName }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="standard" label="规格">
            </el-table-column>
            <!-- <el-table-column prop="purchasePrice" label="单价(元)"></el-table-column> -->
            <el-table-column prop="price" label="调整金额(元)">
              <template slot-scope="scope">
                <el-input v-if="mainTableIndex == scope.$index && pageFlag != 3" v-model="scope.row.price" type="number" @change="priceChange"></el-input>
                <div v-else style="padding: 0 10px;">{{ scope.row.price }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="remark" label="备注">
              <template slot-scope="scope">
                <el-input v-if="mainTableIndex == scope.$index && pageFlag != 3" v-model="scope.row.remark" type="text"></el-input>
                <div v-else style="padding: 0 10px;">{{ scope.row.remark }}</div>
              </template>
            </el-table-column>
          </el-table>
          

        </template>
      </div>
    </div>

  </div>
</template>
<script>
import goodsChange from "../../components/goodsChange.vue";
export default {
  components:{
    goodsChange
  },
  data() {
    return {
      
      formData: {
        price: 0, //调整金额
        warehouseId: "", //仓库ID
        warehouseName: "", //仓库名称
        orderTime: "", //单据日期
        remark: "", //原因
        costAdjustmentChildOrderList:[]
      },
      submitBtnLoad: false,
      addRules: {
        warehouseId: [
          { required: true, message: "请选择仓库", trigger: "blur" },
        ],
        orderTime: [
          { required: true, message: "请选择单据日期", trigger: "blur" },
        ],
      },
      warehouseList: [], //仓库列表
      goodsDialogShow: false, //选择商品弹框
      loading: true,
      alertTlt: "",
      goodsIndex: "", //选择商品的index,用来判断是不是修改
      tableData: [{},{},{},{}],
      //分页数据
      pagesData: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      multipleTable: [],
      ids: [],
      goodsIndexs: [], //选择准备删除的商品
      updateButton: "",
      addButton: "",
      // 1新增 2编辑 3详情
      pageFlag: null,
       // 输入建议弹框
      popupTop: '',
      popupLeft: '',
      popupShow: false,
      searchName: '',
      //主表格当前行下标
      mainTableIndex: null,
      hoverRow: null,
      nowRowData:{},
    };
  },
  watch: {
    $route(to, form) {
      if (to.query.type == 'add') {
        this.alertTlt = '新增调整单'
        this.pageFlag = 1
        this.formData = {}
        this.$set(this.formData, 'costAdjustmentChildOrderList', [])
        
      }
    }
  },
  created() {
    this.getWarehouse();
    this.getGoodsUnit();
    var privilege = JSON.parse(sessionStorage.getItem("authority"));
    privilege.forEach((item) => {
      if (item.authority == "cost_adjustment_order_update") {
        this.updateButton = "1";
      }else if (item.authority == "cost_adjustment_order_add") {
        this.addButton = "1";
      }
    });
  },
  // keep-alive组件缓存生命周期
  activated() {
    // console.log(this.$route.params, this.$route.query)
    let that = this;
    let data = this.$route.params.type ? this.$route.params : this.$route.query
    if (data.type == 'edit') {
      this.alertTlt = "编辑调整单";
      this.pageFlag = 2

      this.$axios
        .get(this.$axios.defaults.basePath + "/costAdjustmentOrder/view", {
          params: { id: data.id },
        })
        .then(function (res) {
          if (res.data.errcode == 0) {
            let data = res.data.data;
            that.formData = data;
            that.tableData = data.costAdjustmentChildOrderList
            console.log(that.formData)
          }
        });
    } else if (data.type == 'details') {
      this.alertTlt = '调整单详情'
      this.pageFlag = 3
      this.$axios
        .get(this.$axios.defaults.basePath + "/costAdjustmentOrder/view", {
          params: { id: data.id },
        })
        .then(function (res) {
          console.log(res)
          if (res.data.errcode == 0) {
            let data = res.data.data;
            that.formData = data;
            that.tableData = data.costAdjustmentChildOrderList
            console.log(that.formData)
          }
        });
    } else if (data.type == 'add') {
      this.alertTlt = '新增调整单'
      this.pageFlag = 1
      this.formData = {}
      this.tableData = [{},{},{},{}]
      this.$set(this.formData, 'costAdjustmentChildOrderList', [])
    } else {
      this.alertTlt = '新增调整单'
    }
  },
  mounted() {
  },
  methods: {
    // 调整价格
    priceChange(e){
      let price = 0
      this.tableData.forEach(item=>{
        if(item.goodsName){
          price += Number(item.price)
        }
      })
      this.$set(this.formData,'price',price)
    },
    // 子组件传递数据
    goodsDataEvent(goods) {
      let _this = this
      if (!goods) { return }
      goods.forEach((item, i) => {
        item.purchasePrice = item.price
        item.goodsId = item.id
        if (i == 0) {
          _this.$set(_this.tableData, _this.mainTableIndex, item)
        } else if (i > 0) {
          _this.tableData.splice(_this.mainTableIndex + 1, 0, item)
        }
      })
      let prices = 0
      goods.forEach(item => {
        if (item.price) {
          prices += Number(item.price)
        }
      })
      this.$set(this.formData, 'price', prices)
    },
    // 获取popup弹框返回的商品数据
    getPopupGoods(data, index) {
      data.purchasePrice = data.price
      data.goodsId = data.id
      this.mainTableIndex = index

      this.$set(this.tableData, index, data)
      let prices = 0
      this.tableData.forEach(item=>{
        if(item.price){
          prices += Number(item.price)
        }
      })
      this.$set(this.formData, 'price', prices)
      this.$emit('changeGoods', this.tableData, '')
    },
    // 获取主表格行下标
    mainTableRowClassName({ row, rowIndex }) {
      row.index = rowIndex
    },
    // 主表格点击行事件
    mainTableRowClick(row, column, event) {
      if (this.mainTableIndex != row.index) {
        this.popupShow = false
      }
      this.mainTableIndex = row.index
      this.nowRowData = row
      // this.$nextTick(()=>{
      //   this.$refs.goodsChange.goodsName = row.goodsName;
      // })
    },
    rowClassName({ row, rowIndex }) {
      row.rowIndex = rowIndex
    },

    // 主表格单元格hover事件--显示加减按钮
    mainTableCellHover(row, column, cell, event) {
      this.hoverRow = row.index
    },
    mainTableCellLeave() {
      this.hoverRow = null
    },
    addRowClick() {
      this.tableData.push({})
    },
    delRowClick(index) {
      this.tableData.splice(index, 1)
    },
    // 获取商品单位
    getGoodsUnit() {
      let _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/goodsUnit/all")
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.goodsUnit = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    //新增/编辑调整单   多选框禁用
    addSelectable(row, index) {
      if (this.alertTlt == "编辑调整单" && this.formData.isTopCarriage == 2) {
        return false;
      } else {
        return true;
      }
    },
    //调整单列表   多选框禁用
    selectable(row, index) {
      if (row.status == 1) {
        return false;
      } else {
        return true;
      }
    },

    //添加 选择仓库
    changeWarehouse(e) {
      for (let i in this.warehouseList) {
        if (this.warehouseList[i].id == e) {
          this.formData.warehouseName = this.warehouseList[i].name;
        }
      }
    },
    //子组件传过来的取消选择商品弹框事件
    cancelDialog() {
      this.goodsDialogShow = false;
    },
    //选择商品
    changeGoods(index) {
      if (this.alertTlt == "编辑调整单" && this.formData.isTopCarriage == 2) {
        return;
      } else {
        this.goodsIndex = index + 1;
        this.goodsDialogShow = true;
      }
    },
    //GET 仓库
    getWarehouse() {
      let that = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/warehouse/getWarehouse", {
          params: {},
        })
        .then(function (res) {
          if (res.data.errcode == 0) {
            that.warehouseList = res.data.data;
          }
        });
    },
    //添加调整单 商品列表左侧复选框选择
    handleSelectionChangeGoods(val) {
      var index = [];
      for (let i in val) {
        index.push(Number(val[i].index));
      }
      this.goodsIndexs = index;
    },
    handleSelectionChange(val) {
      var _this = this;
      _this.ids = [];
      this.multipleSelection = val;
      this.multipleSelection.forEach((item) => {
        _this.ids.push(item.id);
      });
    },
    handleSubmit(formData) {
      var _this = this;
      let api = "";
      let arr = [];
      this.$refs[formData].validate((val) => {
        if (val) {
          console.log(_this.formData)
          
          _this.submitBtnLoad = true;
          if (_this.alertTlt == "编辑调整单") {
            api = "/costAdjustmentOrder/update";
            // console.log(_this.tableData)
            _this.tableData.forEach(item => {
              // 如果有值表示为已存在的商品数据
              if (item.costAdjustmentOrderId) {
                let json = {
                  id: item.id,
                  costAdjustmentOrderId:item.costAdjustmentOrderId,
                  goodsId: item.goodsId,
                  goodsName: item.goodsName,
                  price: item.price,
                  remark: item.remark,
                  standard: item.standard
                }
                arr.push(json)
              }else{ 
                let json = {
                  id: '',
                  costAdjustmentOrderId: '',
                  goodsId: item.id,
                  goodsName: item.goodsName,
                  price: item.price,
                  remark: item.remark,
                  standard: item.standard
                }
                arr.push(json)
              }
            })
          } else {
            api = "/costAdjustmentOrder/add";
            _this.tableData.forEach(item => {
              if (item.goodsName) {
                let json = {
                  goodsId: item.id,
                  goodsName: item.goodsName,
                  price: item.price,
                  remark: item.remark,
                  standard: item.standard
                }
                arr.push(json)
              }
            })
          }
          if (arr.length == 0) {
            this.$message({
              message: "最少选择一种商品！",
              type: "error",
            });
            return;
          }
          this.$set(this.formData, 'costAdjustmentChildOrderList', arr)
          this.$axios({
            url: _this.$axios.defaults.basePath + api,
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            data: _this.formData,
          }).then(function (res) {
            if (res.data.errcode == 0) {
              _this.submitBtnLoad = false;
              _this.$message({
                message: "操作成功",
                type: "success",
              });
              _this.$router.push({ name: 'costChange',params:{type:'update'}});
              _this.formData = {};
              _this.$set(_this.formData, 'costAdjustmentChildOrderList', [])
            } else {
              _this.submitBtnLoad = false;
              _this.$message.error(res.data.errmsg);
            }
          }).catch(() => {
            _this.submitBtnLoad = false;
          });
        } else {
          return false;
        }
      });
    },
    //关闭弹框
    cancelAdd(s) {
      this[s] = false;
    },
    //删除商品
    delGoods() {
      let indexs = this.goodsIndexs.sort(function (a, b) {
        return b - a;
      });
      for (let i in indexs) {
        this.formData.costAdjustmentChildOrderList.splice(indexs[i], 1);
      }
      this.$refs.multipleTable1.clearSelection();
      this.goodsIndexs = [];
      for (let i in this.formData.costAdjustmentChildOrderList) {
        this.formData.costAdjustmentChildOrderList[i].index = i;
        Vue.set(
          this.formData.costAdjustmentChildOrderList,
          i,
          this.formData.costAdjustmentChildOrderList[i]
        );
      }
    },
    // 规格比修改
    changeScaledValue(row, e, name) {
      if (!e || e <= 0) {
        this.$message({
          message: "请输入大于0的数值",
          type: "error",
        });
        row[name] = "";
        return;
      } else {
        row[name] = e;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.tip {
  color: sandybrown;
  line-height: 1.5rem;
  margin-top: 0.9375rem;
  padding-left: 1.25rem;
}
.addOrDel {
  i {
    cursor: pointer;
    font-size: 20px;
    display: inline-block;
    margin: 0 5px;
  }
}
</style>